import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && jwtDecode(serviceToken)) {
                    const decoded = jwtDecode(serviceToken);

                    setSession(serviceToken);
                    const response = await axios.get(`/dmrv-service/consumers/manage/${decoded.id}`);
                    const user = response.data.data;

                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    dispatch({ type: LOGOUT });
                }
            } catch (err) {
                console.error(err);
                dispatch({ type: LOGOUT });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        const response = await axios.post('/dmrv-service/consumers/auth/login', { email, password });
        const { token, user } = response.data;

        window.localStorage.setItem('serviceToken', token);
        setSession(token);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    const verifyOTP = async (email, verificationCode) => {
        const response = await axios.post('/dmrv-service/consumers/auth/verify-otp', { email, verificationCode });
        const { token } = response.data;
        window.localStorage.setItem('serviceToken', token);
        setSession(token);

        // Decode the token to get the user ID
        const decoded = jwtDecode(token);
        // Fetch the latest user information
        const userResponse = await axios.get(`/dmrv-service/consumers/manage/${decoded.id}`);
        const user = userResponse.data.data;

        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    // const logout = async () => {
    //     await axios.post('/dmrv-service/consumers/auth/logout');
    //     setSession(null);
    //     dispatch({ type: LOGOUT });
    // };
    const logout = async () => {
        try {
            await axios.post('/dmrv-service/consumers/auth/logout');
        } catch (error) {
            console.error('Error during logout:', error);
        } finally {
            localStorage.clear();
            setSession(null);
            dispatch({ type: LOGOUT });
        }
    };

    const forgotPassword = async (email) => {
        const response = await axios.post('/dmrv-service/consumers/auth/forgot-password', { email });

        if (response.data.success) {
            return { success: true, message: 'Verification code sent to your email address.' };
        } else {
            throw new Error('Failed to send verification code');
        }
    };

    const verifyPasswordResetCode = async (email, code) => {
        try {
            const response = await axios.post('/dmrv-service/consumers/auth/verify-password-reset', { email, code });
            if (response.data.success) {
                return { success: true, message: 'Code verified successfully' };
            } else {
                throw new Error(response.data.msg || 'Code verification failed');
            }
        } catch (err) {
            console.error('Error verifying reset code:', err);
            return { success: false, message: err?.msg || 'Verification failed' };
        }
    };

    const resetPassword = async (email, newPassword) => {
        try {
            const response = await axios.post('/dmrv-service/consumers/auth/reset-password', {
                email,
                newPassword
            });
            if (response.data.success) {
                return { success: true, message: 'Password has been reset successfully' };
            } else {
                throw new Error('Password reset failed');
            }
        } catch (err) {
            console.error('Error resetting password:', err);
            return { success: false, message: err.response?.data.msg || 'Reset failed' };
        }
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout,
                updateProfile,
                verifyOTP,
                verifyPasswordResetCode,
                resetPassword,
                forgotPassword
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
