import PropTypes from 'prop-types';
import { useContext, useState, useEffect } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, Card, CircularProgress, Grid, InputAdornment, MenuItem, TextField, Typography, Popper } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';
import CookstoveContext from 'contexts/CookstoveContext';

// assets
import { IconAdjustmentsHorizontal, IconPresentation, IconX, IconCooker } from '@tabler/icons-react';
import { shouldForwardProp } from '@mui/system';

const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(TextField, { shouldForwardProp })(({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
    }
}));

const MobileSearch = ({ value, setValue, cookstoves }) => {
    const theme = useTheme();

    const handleSelectChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', display: { xs: 'block', md: 'none' }, mx: 2 }}>
            <TextField
                select
                id="input-search-header"
                value={value}
                onChange={handleSelectChange}
                placeholder="Select Cookstove"
                InputProps={{
                    startAdornment: <IconCooker stroke={1.5} size="16px" color={theme.palette.grey[500]} />
                }}
                aria-describedby="search-helper-text"
                sx={{ width: '100%' }}
            >
                {cookstoves.map((cookstove) => (
                    <MenuItem key={cookstove.deviceId} value={cookstove.deviceId}>
                        IMEI: {cookstove.deviceId}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    cookstoves: PropTypes.array.isRequired
};

const SelectCookstoveSection = () => {
    const theme = useTheme();
    const { cookstoves, selectedCookstove, setSelectedCookstove, isLoading, error } = useContext(CookstoveContext);
    const [selectedCookstoveId, setSelectedCookstoveId] = useState(
        () => localStorage.getItem('selectedCookstove') || selectedCookstove?.deviceId || ''
    );

    useEffect(() => {
        if (cookstoves.length > 0 && !selectedCookstoveId) {
            const initialCookstove = cookstoves[0];
            setSelectedCookstoveId(initialCookstove.deviceId);
            setSelectedCookstove(initialCookstove);
            localStorage.setItem('selectedCookstove', initialCookstove.deviceId);
        }
    }, [cookstoves, selectedCookstoveId, setSelectedCookstove]);

    const handleCookstoveChange = (event) => {
        const cookstoveId = event.target.value;
        setSelectedCookstoveId(cookstoveId);
        const selected = cookstoves.find((stove) => stove.deviceId === cookstoveId);
        setSelectedCookstove(selected);
        localStorage.setItem('selectedCookstove', cookstoveId);
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px', ml: 5 }}>
                <CircularProgress size={24} />
                <Typography sx={{ ml: 2 }}>Loading...</Typography>
            </Box>
        );
    }

    // if (error) {
    //     return (
    //         <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50px', ml: 5 }}>
    //             <Typography color="error">Error fetching cookstoves: {error.message}</Typography>
    //         </Box>
    //     );
    // }

    return (
        <>
            <MobileSearch
                value={selectedCookstoveId}
                setValue={(id) => handleCookstoveChange({ target: { value: id } })}
                cookstoves={cookstoves}
            />
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <OutlineInputStyle
                    id="input-select-cookstove-header"
                    value={selectedCookstoveId || ''}
                    onChange={handleCookstoveChange}
                    select
                    placeholder="Select Cookstove"
                    aria-describedby="search-helper-text"
                    inputProps={{ 'aria-label': 'weight' }}
                >
                    <MenuItem value="" disabled>
                        Select Cookstove
                    </MenuItem>
                    {cookstoves.map((cookstove) => (
                        <MenuItem key={cookstove.deviceId} value={cookstove.deviceId}>
                            IMEI: {cookstove.deviceId}
                        </MenuItem>
                    ))}
                </OutlineInputStyle>
            </Box>
        </>
    );
};

export default SelectCookstoveSection;
