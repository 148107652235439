import React, { createContext, useState, useEffect } from 'react';
import { useGetDevices } from 'api/cookstove';
import useAuth from 'hooks/useAuth';

const initialState = {
    cookstoves: [],
    selectedCookstove: null,
    setCookstoves: () => {},
    setSelectedCookstove: () => {}
};

const CookstoveContext = createContext(initialState);

export const CookstoveProvider = ({ children }) => {
    const { user } = useAuth();
    const deviceIds = user?.deviceId || [];
    const { devices, isLoading, error } = useGetDevices(deviceIds);

    const [cookstoves, setCookstoves] = useState([]);
    const [selectedCookstove, setSelectedCookstove] = useState(null);

    useEffect(() => {
        if (!isLoading && devices && devices.length > 0) {
            setCookstoves(devices);
            const savedCookstoveId = localStorage.getItem('selectedCookstove');
            const initialCookstove = devices.find((stove) => stove.deviceId === savedCookstoveId) || devices[0];
            setSelectedCookstove(initialCookstove);
            localStorage.setItem('selectedCookstove', initialCookstove.deviceId);
        }
    }, [devices, isLoading]);

    const handleSetSelectedCookstove = (cookstove) => {
        setSelectedCookstove(cookstove);
        localStorage.setItem('selectedCookstove', cookstove.deviceId);
    };

    return (
        <CookstoveContext.Provider
            value={{
                cookstoves,
                selectedCookstove,
                setCookstoves,
                setSelectedCookstove: handleSetSelectedCookstove,
                isLoading,
                error
            }}
        >
            {children}
        </CookstoveContext.Provider>
    );
};

export default CookstoveContext;
