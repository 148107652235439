import React, { useEffect, useState } from 'react';

// material-ui
import CloseIcon from '@mui/icons-material/Close';
import { Button, Snackbar, Alert, AlertTitle, IconButton } from '@mui/material';

const InstallPrompt = () => {
    const [installPromptEvent, setInstallPromptEvent] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            if (!installPromptEvent) {
                // Only set this if there isn't already one saved
                setInstallPromptEvent(e);
                setOpen(true);
            }
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, [installPromptEvent]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleInstallApp = () => {
        if (installPromptEvent) {
            installPromptEvent.prompt();
            installPromptEvent.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setInstallPromptEvent(null);
                setOpen(false);
            });
        }
    };

    return (
        <Snackbar open={open} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert
                onClose={handleClose}
                severity="info"
                action={
                    <>
                        <Button color="secondary" variant="contained" onClick={handleInstallApp}>
                            Install
                        </Button>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                }
                sx={{ width: '100%' }}
            >
                <AlertTitle>Verst Clean Cooking App</AlertTitle>
                Enhance your experience with our Verst Clean Cooking app. Install it today!
            </Alert>
        </Snackbar>
    );
};

export default InstallPrompt;
