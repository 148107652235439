// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconKey,
    IconMessageChatbot,
    IconLayoutDashboard,
    IconDeviceDesktopAnalytics,
    IconCooker,
    IconReceipt,
    IconReportMoney,
    IconScript
} from '@tabler/icons-react';

// constant
const icons = {
    IconMessageChatbot,
    IconKey,
    IconLayoutDashboard,
    IconDeviceDesktopAnalytics,
    IconCooker,
    IconReceipt,
    IconReportMoney,
    IconScript
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const consumer = {
    id: 'consumer',
    title: <FormattedMessage id="consumer" />,
    caption: <FormattedMessage id="clean-cooking" />,
    icon: icons.IconKey,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconLayoutDashboard,
            breadcrumbs: true
        },
        {
            id: 'cookstoves',
            title: <FormattedMessage id="cookstoves" defaultMessage="My Cookstoves" />,
            type: 'item',
            url: '/cookstoves',
            icon: icons.IconCooker,
            breadcrumbs: true
        },
        {
            id: 'invoices',
            title: <FormattedMessage id="invoices" defaultMessage="Invoices" />,
            type: 'item',
            url: '/invoices',
            icon: icons.IconReceipt,
            breadcrumbs: true
        },
        {
            id: 'payments',
            title: <FormattedMessage id="payments" defaultMessage="Payments" />,
            type: 'item',
            url: '/payments',
            icon: icons.IconReportMoney,
            breadcrumbs: true
        },
        {
            id: 'analytics',
            title: <FormattedMessage id="analytics" defaultMessage="Analytics" />,
            type: 'item',
            url: '/analytics',
            icon: icons.IconDeviceDesktopAnalytics,
            breadcrumbs: true
        },
        {
            id: 'support',
            title: <FormattedMessage id="support" />,
            type: 'collapse',
            icon: icons.IconMessageChatbot,
            children: [
                {
                    id: 'create-ticket',
                    title: <FormattedMessage id="create-ticket" />,
                    type: 'item',
                    url: '/tickets/create',
                    target: false
                },
                {
                    id: 'my-tickets',
                    title: <FormattedMessage id="my-tickets" />,
                    type: 'item',
                    url: '/tickets/list',
                    target: false
                }
            ]
        }
    ]
};

export default consumer;
