import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import axiosServices from 'utils/axios';

// Fetcher function for useSWR
const fetcher = (url) => axiosServices.get(url).then((res) => res.data);

// Hook to get device data by ID
export function useGetDeviceDataById(deviceId) {
    const endpoint = `/dmrv-mqtt-service/analytics/device-summary/${deviceId}`;

    const { data, error, isValidating } = useSWR(endpoint, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: true
    });

    return useMemo(
        () => ({
            deviceData: data,
            isLoading: isValidating,
            error
        }),
        [data, isValidating, error]
    );
}

// Hook to get device data by ID
export function useGetDevice(deviceId) {
    const endpoint = `/dmrv-mqtt-service/${deviceId}`;

    const { data, error, isValidating } = useSWR(endpoint, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: true
    });

    return useMemo(
        () => ({
            device: data,
            isLoading: isValidating,
            error
        }),
        [data, isValidating, error]
    );
}

// Hook to get device data by IDs
export function useGetDevices(deviceIds) {
    const fetchDevices = async () => {
        const responses = await Promise.all(deviceIds.map((id) => fetcher(`/dmrv-mqtt-service/${id}`)));
        return responses;
    };

    const shouldFetch = deviceIds && deviceIds.length > 0;
    const { data, error, isValidating } = useSWR(shouldFetch ? `/dmrv-mqtt-service/devices` : null, fetchDevices, {
        shouldRetryOnError: false,
        revalidateOnFocus: true,
        revalidateIfStale: true
    });

    return useMemo(
        () => ({
            devices: data || [],
            isLoading: isValidating,
            error
        }),
        [data, isValidating, error]
    );
}

// Hook to get device emissions reduction data
export function useCalculateDeviceER(projectId, deviceId) {
    const endpoint = `/dmrv-service/api/v1/device-data/${projectId}?deviceId=${deviceId}`;

    const { data, error, isValidating } = useSWR(endpoint, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: true
    });

    return useMemo(
        () => ({
            deviceERData: data,
            isLoading: isValidating,
            error
        }),
        [data, isValidating, error]
    );
}

// Hook to get device analytics data
export function useGetDeviceAnalytics(deviceId) {
    const endpoint = `/dmrv-mqtt-service/analytics/device-data/${deviceId}`;

    const { data, error, isValidating } = useSWR(endpoint, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: true
    });
    return useMemo(
        () => ({
            deviceAnalytics: data?.data,
            isLoading: isValidating,
            error
        }),
        [data, isValidating, error]
    );
}
